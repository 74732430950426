import * as common from './common';
import * as config from '../utils/config';
import * as auth from './authentication';
import * as httpClient from '../utils/httpClient';
import * as wpConnector from './Connectors/worldPay';
import { FormattedConnector } from './common';

export interface PaymentInstrument {
    type: 'BANK' | 'CARD'; // TODO: Verify this is correct
    account_reference?: string;
    accountName?: string;
    accountType?: string;
    address?: string;
    address2?: string;
    availableBalance?: number;
    bank_name?: string;
    bankCode?: string;
    bankType?: string;
    branchName?: string;
    cardNumberLast4?: number;
    cardType?: string;
    city?: string;
    connectorId?: number;
    countryCode?: string;
    created_date?: string;
    currency: string;
    email?: string;
    expiryDate?: string;
    expMonth?: number;
    expYear?: number;
    firstName?: string;
    identificationType?: string;
    identificationValue?: string;
    idNumber?: string;
    instrumentType?: number;
    internalBankCode?: string;
    lastName?: string;
    payment_instrument_id?: number;
    phoneNumber?: string;
    plaidEligible?: boolean;
    plaidLink?: boolean;
    requestReference?: string;
    residencyNumber?: string;
    routing_reference?: string;
    source?: number;
    state?: string;
    status?: number;
    swift?: string;
    updated_date?: string;
    userType?: string;
    uuid: string;
    verificationType?: string;
    zipCode?: string;
}

export interface PaymentDetails {
    saveCard: boolean;
    currency: string;
    showIframe: boolean;
    wptarget: string;
    paymentInstrumentUuid?: string;
    connector?: string;
    cardDetails?: {
        cardNumber: string;
        cardHolderName: string;
        cardExpiryMonth: number;
        cardExpiryYear: number;
        cardSecurityCode: string;
    };
    applyClientCredit: boolean;
    clientCreditDetails?: {
        accountNumber: string;
        amount: string;
    };
    applyuWallet: boolean;
    uwalletDetails?: {
        accountNumber: string;
        amount: string;
    };
    applyuWalletFunding: boolean;
    uwalletFundingDetails?: {
        accountNumber: string;
        amount: string;
    };
    applyuWalletTransfer: boolean;
    uwalletTransferDetails?: {
        accountNumber: string;
        amount: string;
    };
    applyuWalletQC: boolean;
    uwalletQCDetails?: {
        accountNumber: string;
        amount: string;
    };
}

let paymentInstruments: PaymentInstrument[] | null = null;

export const resetPayment = () => {
    paymentInstruments = null;
};

// TODO: use this enum instead of the below functions
// enum PaymentInstrumentType {
//     BANK = 1,
//     CARD = 2
// }

const paymentInstrumentId = (type: 'CARD' | 'BANK') => {
    switch(type) {
        case 'CARD':
            return 2;
        case 'BANK':
            return 1;
    }
};

const paymentInstrumentEnum= (type: 1 | 2) => {
    switch(type) {
        case 1:
            return 'BANK';
        case 2:
            return 'CARD';
    }
};

const getPaymentData = (paymentObj: PaymentInstrument): PaymentInstrument => {
    const paymentFormatted: PaymentInstrument = {
        uuid: paymentObj.uuid,
        currency: paymentObj.currency,
        type: paymentInstrumentEnum(paymentObj.instrumentType as 1 | 2)
    };
    if (paymentFormatted.type === 'CARD') {
        paymentFormatted.cardType = paymentObj.cardType;
        paymentFormatted.expiryDate = `${paymentObj.expMonth} / ${paymentObj.expYear}`;
        paymentFormatted.cardNumberLast4 = paymentObj.cardNumberLast4;
    } else if (paymentFormatted.type === 'BANK') {
        paymentFormatted.accountName = paymentObj.accountName;
        paymentFormatted.accountType = paymentObj.accountType;
        paymentFormatted.account_reference = paymentObj.account_reference;
    }
    return paymentFormatted;
};

export interface PaymentInstrumentsFilters {
    type: 'CARD' | 'BANK';
}

interface PaymentInstrumentsFiltersInternal extends PaymentInstrumentsFilters {
    isInternal: boolean;
}

export const getPaymentInstruments = async (filters: PaymentInstrumentsFilters): Promise<PaymentInstrument[]> => {
    return getPaymentInstrumentsInternal({ ...filters, isInternal: false });
};

const getPaymentInstrumentsInternal = async (filters: PaymentInstrumentsFiltersInternal): Promise<PaymentInstrument[]> => {
    if(paymentInstruments && paymentInstruments.length > 0) {
        return (filters?.isInternal) ? paymentInstruments : paymentInstruments.map(data => getPaymentData(data)) ?? [];
    }
    const reqBody = await config.setGetBodyParams({ instrument_type: `${paymentInstrumentId(filters.type)}`, status: '1', size: '100' });
    const result = await httpClient.get<{ data?: { results: PaymentInstrument[] }, status: number, error?: { message: string } }>(`consumers/payment-instrument?${reqBody}`);
    if(result?.status === 1) {
        paymentInstruments = result?.data?.results.filter(inst => inst.status == 1) ?? null;
        return ((filters?.isInternal) ? paymentInstruments : result?.data?.results?.filter(inst => inst.status == 1)?.map(data => getPaymentData(data))) ?? [];
    } else {
        throw new Error(result?.error?.message);
    }
};

const getPaymentCheckoutMode = (details: PaymentDetails) => {
    if ((details.applyClientCredit || details.applyuWallet || details.applyuWalletFunding || details.applyuWalletTransfer || details.applyuWalletQC) && (details?.paymentInstrumentUuid || details?.connector)) {
        return 3;
    } else if (details.applyClientCredit || details.applyuWallet || details.applyuWalletFunding || details.applyuWalletTransfer || details.applyuWalletQC) {
        return 2;
    } else {
        return 1;
    }
};

const paymentRequestBodyMap = async(details: PaymentDetails) => {
    let selectedChannel: FormattedConnector | null = null;
    let selectedPaymentInstrument: PaymentInstrument | null = null;
    const userDetails = await auth.getUserDetails();
    const kountDetails = await config.kountInitialize(userDetails);

    // find selectedPaymentChannel and Connector=========
    if(details?.connector) {
        const formattedChannels = await common.getPaymentConnectors();
        selectedChannel = formattedChannels.find(data => data.displayName == details.connector) ?? null;
    }

    // find selectedPaymentInstrument and Connector==================
    if(details?.paymentInstrumentUuid) {
        const paymentInstruments = await getPaymentInstrumentsInternal({type: 'CARD', isInternal: true});
        selectedPaymentInstrument = paymentInstruments.find(data => data.uuid ==  details.paymentInstrumentUuid) ?? null;
        
        const formattedChannels = await common.getPaymentConnectors();
        selectedChannel = formattedChannels.find(data => data.id == selectedPaymentInstrument?.connectorId) ?? null;
    }

    if((details.applyClientCredit || details.applyuWallet || details.applyuWalletFunding || details.applyuWalletTransfer || details.applyuWalletQC) && !details.connector) {
        // TODO: Find out what this was for, since these vars are not used (or defined)
        // selectedConnector = 1;
        // selectedPaymentChannelId = 1;
    }
    
    return {
        deviceType: await config.getDeviceInfo(),
        deviceOS: await config.getDeviceOs(),
        needBalanceCheck: true,
        order_token: await auth.getOrderToken(),
        kountSessionId: kountDetails?.sessionID,
        deviceInformation: kountDetails?.machineNumber,
        connectorId: selectedChannel?.id,
        paymentChannelId: selectedChannel?.paymentChannelId,
        paymentInstrumentId: selectedPaymentInstrument?.payment_instrument_id,

        clientCreditId: (details.applyClientCredit) ? details?.clientCreditDetails?.accountNumber : null,
        fiatAccountNumber: (details.applyuWallet) ? details?.uwalletDetails?.accountNumber : null,
        loyaltyAccountNumber: (details.applyuWalletFunding) ? details?.uwalletFundingDetails?.accountNumber  : null,
        utransferAccountNumber: (details.applyuWalletTransfer) ? details?.uwalletTransferDetails?.accountNumber  : null,
        qulifyingCreditAccountNumber: (details.applyuWalletQC) ? details?.uwalletQCDetails?.accountNumber  : null,

        maxClientCredit: (details.applyClientCredit) ? details?.clientCreditDetails?.amount : null,
        fiatMaxAmount: (details.applyuWallet) ? details?.uwalletDetails?.amount : null,
        loyaltyMaxAmount: (details.applyuWalletFunding) ? details?.uwalletFundingDetails?.amount  : null,
        utransferMaxAmount: (details.applyuWalletTransfer) ? details?.uwalletTransferDetails?.amount  : null,
        qulifyingCreditMaxAmount: (details.applyuWalletQC) ? details?.uwalletQCDetails?.amount  : null,

        paymentCheckoutMode: getPaymentCheckoutMode(details),
    };
};

export const submitPayment = async(details: PaymentDetails) => {
    if(details.saveCard) {
        await common.saveCard(details.saveCard);
    }
    const requestBody = await paymentRequestBodyMap(details);
    const result = await httpClient.post<httpClient.ApiResponse<{ redirectUrl: string, status: number }>>('transaction/checkout', requestBody);
    if(result?.status === 1) {
        if(details.connector === 'WORLDPAY' && result.data?.redirectUrl) {
            if (!details.cardDetails && !details.showIframe) {
                throw new Error('CARD_DETAILS_REQUIRED');
            } else if (details.showIframe && !details.wptarget) {
                throw new Error('WP_TARGET_REQUIRED');
            } else if (details.showIframe && details.wptarget) {
                const reqOptions: wpConnector.WorldPayOptions =  { 
                    wpUrl: result.data?.redirectUrl,
                    showIframe: details.showIframe,
                    target: details?.wptarget
                };
                const connectorResult = await wpConnector.addWorldPayCard(details?.currency, reqOptions);
                if(connectorResult === 'card_added_successful') {
                    return 'PAYMENT_SUCCESSFUL';
                } else {
                    return 'PAYMENT_FAILED';
                }
            } else if (details.cardDetails) {
                const reqOptions: wpConnector.WorldPayOptions =  { 
                    cardDetails: details.cardDetails, 
                    wpUrl: result.data?.redirectUrl,
                    showIframe: false,
                    target: details?.wptarget ?? null
                };
                const connectorResult = await wpConnector.addWorldPayCard(details?.currency, reqOptions);
                if(connectorResult === 'card_added_successful') {
                    return 'PAYMENT_SUCCESSFUL';
                } else {
                    return 'PAYMENT_FAILED';
                }
            } else {
                return 'PAYMENT_FAILED';
            }
        } else if (result.data.status == 99){
            return 'PAYMENT_FAILED';
        } else {
            return 'PAYMENT_SUCCESSFUL';
        }
    } else {
        throw new Error(result?.error?.message);
    }
};


